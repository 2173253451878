import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const UspIcon = styled.div`
  width: 47px;
  height: 47px;
  background: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
`

const UspTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.family.secondary};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.light};
  max-width: 150px;
  text-align: center;

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`

const StyledUsps = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

interface UspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usps
}

const Usps: React.FC<UspsProps> = ({ fields }) => (
  <StyledUsps>
    <div className="container pb-5 py-lg-5">
      <div className="row">
        {fields.items?.map((edge, index: number) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="col-4 col-md px-3 mt-3 mt-lg-0 d-flex flex-column align-items-center"
          >
            <UspIcon>
              <Plaatjie image={edge?.icon} alt={edge?.title || 'VT Tuinen'} />
            </UspIcon>
            <UspTitle className="mt-4 mb-0">{edge?.title}</UspTitle>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-6 mt-4 mx-auto text-center">
          <ButtonSecondary className="mt-4" to={fields?.link?.url || ''}>
            {fields?.link?.title}
          </ButtonSecondary>
        </div>
      </div>
    </div>
  </StyledUsps>
)

export default Usps
